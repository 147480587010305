import React, { useMemo, useState } from 'react';

import {
    DataGrid,
    GridColDef,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    getGridDateOperators,
    getGridStringOperators,
    GridRowParams,
} from '@mui/x-data-grid';
import { transformKeyToTitle } from '../utils/transformKeyToTitle';
import Button from '@mui/material/Button';
import RefreshIcon from '@mui/icons-material/Refresh';
import { formatDate } from '../utils/formatDate';

import { useAppDispatch, useAppSelector } from '../store/hooks';
import { loadMapUploads, selectMapUploads } from '../store/maps.reducer';
import { CopyTextCell } from './GridCells/CopyTextCell';
import { useManageGrid } from '../hooks/useManageGrid';
import { MapUploadListItem } from '../interfaces';
import { SelectedMapUploadDialog } from './SelectedMapUploadDialog';
import { MapUploadActionsCell } from './GridCells/MapUploadActionsCell';
import { ProcessingStatusCell } from './GridCells/MapUploadStatusCell';

function Toolbar() {
    const dispatch = useAppDispatch();

    const onReload = () => {
        dispatch(loadMapUploads());
    };

    return (
        <>
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <Button startIcon={<RefreshIcon />} color="primary" onClick={onReload}>
                    Reload
                </Button>
            </GridToolbarContainer>
        </>
    );
}

export const MapUploadsTableGrid = () => {
    const mapUploads = useAppSelector(selectMapUploads);
    const [selectedRow, setSelectedRow] = useState<MapUploadListItem | null>(null);

    const {
        sortModel,
        columnsConfig,
        columnsWidth,
        gridFilterModel,
        handleColumnVisibilityChange,
        handleColumnWidthChange,
        handleSortModelChange,
        handleFilterModelChange,
        onCellKeyDown,
        gridStateSynced,
    } = useManageGrid('map/uploads');

    const columns: GridColDef[] = useMemo(() => {
        return [
            { key: 'id', renderCell: CopyTextCell },
            { key: 'name', renderCell: CopyTextCell },
            { key: 'status', renderCell: ProcessingStatusCell, width: 100 },
            { key: 'actions', renderCell: MapUploadActionsCell, width: 200 },
            { key: 'upload_id', renderCell: CopyTextCell },
            { key: 'directory', renderCell: CopyTextCell },
            { key: 'source', renderCell: CopyTextCell, width: 150 },
            { key: 'zoom', renderCell: CopyTextCell, width: 100 },
            { key: 'createdBy', renderCell: CopyTextCell },
            {
                key: 'createdAt',
                valueFormatter: formatDate,
                filterOperators: getGridDateOperators,
            },
        ].map((col) => {
            return {
                field: col.key,
                // type: (col.type || 'string') as GridColType,
                headerName: transformKeyToTitle(col.key),
                renderCell: col.renderCell,
                valueFormatter: col.valueFormatter,
                width: columnsWidth[col.key] || col.width || 250,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                filterOperators: col.filterOperators ? col.filterOperators() : getGridStringOperators(),
            };
        });
    }, [columnsWidth]);

    const rows = mapUploads?.map((upload) => {
        const row: any = {
            ...upload,
            id: upload._id,
        };

        if (row.createdAt) row.createdAt = new Date(row.createdAt);

        return row;
    });

    const onRowDoubleClick = (params: GridRowParams) => {
        setSelectedRow(params.row as MapUploadListItem);
    };

    return (
        <>
            {gridStateSynced && (
                <>
                    <DataGrid
                        style={{ minHeight: '200px' }}
                        rows={rows}
                        columns={columns}
                        onRowDoubleClick={onRowDoubleClick}
                        slots={{
                            toolbar: () => <Toolbar />,
                        }}
                        disableRowSelectionOnClick
                        onCellKeyDown={onCellKeyDown}
                        filterModel={gridFilterModel}
                        sortModel={sortModel}
                        onSortModelChange={handleSortModelChange}
                        onColumnWidthChange={handleColumnWidthChange}
                        onColumnVisibilityModelChange={handleColumnVisibilityChange}
                        onFilterModelChange={handleFilterModelChange}
                        columnVisibilityModel={columnsConfig}
                    />

                    {selectedRow && (
                        <SelectedMapUploadDialog
                            open={!!selectedRow}
                            mapUpload={selectedRow}
                            onClose={() => {
                                setSelectedRow(null);
                            }}
                        />
                    )}
                </>
            )}
        </>
    );
};
