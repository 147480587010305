import axios from 'axios';
import config from '../config';
import { ProcessingBuild } from '../interfaces';

export const getProcessingBuild = (id: string): Promise<ProcessingBuild> => {
    const url = `${config.API_URL}/maps/processing-builds/${id}`;

    return axios.get(url).then((response: any) => {
        return response.data.data.build;
    });
};
