import React from 'react';
import { MapContainer, TileLayer, GeoJSON } from 'react-leaflet';
import { getGeoJsonPolygonCenter } from '../utils/getGeoJsonPolygonCenter';
import { defaultMapCenter } from '../config';
import { MultiPolygon, Polygon } from 'geojson';
import { MapFitBounds } from './SourceTilesViewer/MapFitBounds';

interface Props {
    geoJson: Polygon | MultiPolygon | null;
}

export const MapPolygonPreviewer = ({ geoJson }: Props) => {
    const center = geoJson ? getGeoJsonPolygonCenter(geoJson) : defaultMapCenter;

    return (
        <MapContainer center={center} zoom={7} style={{ height: '100%', width: '100%' }}>
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution="&copy; OpenStreetMap contributors"
            />
            {geoJson && (
                <>
                    <GeoJSON data={geoJson} style={{ fillColor: '#778d31', color: '#778d31' }} />
                    <MapFitBounds geoJson={geoJson} />
                </>
            )}
        </MapContainer>
    );
};
