import axios from 'axios';
import config from '../config';

export const getProcessedMapDownloadLink = (id: string): Promise<string> => {
    const url = `${config.API_URL}/maps/processed-maps/${id}/download`;

    return axios.get(url).then((response: any) => {
        return response.data.data.presigned_url;
    });
};
