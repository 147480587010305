import LogoutIcon from '@mui/icons-material/Logout';
import { Tooltip } from '@mui/material';
import { useCognitoUser } from '../hooks/useCognitoUser';

export const Logout = () => {
    const { logout } = useCognitoUser();

    return (
        <>
            <Tooltip title="Вийти" placement="top">
                <LogoutIcon onClick={logout} />
            </Tooltip>
        </>
    );
};
