import axios from 'axios';
import config from '../config';
import { MapUploadListItem } from '../interfaces';

export const getMapUploads = (): Promise<MapUploadListItem[]> => {
    const url = `${config.API_URL}/tile-sources/uploads`;

    return axios.get(url).then((response: any) => {
        return response.data.data.uploads;
    });
};
