import React, { useMemo, useState } from 'react';

import jsPDF from 'jspdf';
import {
    DataGrid,
    GridColDef,
    getGridNumericOperators,
    getGridStringOperators,
    getGridDateOperators,
    GridToolbarContainer,
    GridSlots,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
} from '@mui/x-data-grid';
import { ActivationStick, Device, DeviceWithSticks, UserRoles, VersionValidity } from '../interfaces';
import { transformKeyToTitle } from '../utils/transformKeyToTitle';
import { GridColType } from '@mui/x-data-grid/models/colDef/gridColType';
import Button from '@mui/material/Button';
import { UpdateVersionDialog } from './UpdateVersionDialog';
import {
    loadActivationSticks,
    loadOnboardDevices,
    loadSystemConfigurations,
    selectOnboardDevices,
    selectSelectedOnboardDeviceTableRows,
    setSelectedOnboardDeviceTableRows,
} from '../store/devices.reducer';
import RefreshIcon from '@mui/icons-material/Refresh';
import UpgradeIcon from '@mui/icons-material/Upgrade';
import AddHomeIcon from '@mui/icons-material/AddHome';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import KeyIcon from '@mui/icons-material/Key';

import { useAppDispatch, useAppSelector } from '../store/hooks';
import { GetSecretsDialog } from './GetSecretsDialog';
import { hasAllowedRole } from '../utils/hasAllowedRole';
import { useCognitoUser } from '../hooks/useCognitoUser';
import { AttachOrganizationDialog } from './AttachOrganizationDialog';
import { CreateDevicesDialog } from './CreateDevicesDialog';
import { formatDate } from '../utils/formatDate';
import { RemoveDevicesDialog } from './RemoveDevicesDialog';
import { useManageGrid } from '../hooks/useManageGrid';
import { CopyTextCell } from './GridCells/CopyTextCell';
import { OrganizationLabelCell } from './GridCells/OrganizationLabelCell';
import { ActivationSticksCell } from './GridCells/ActivationSticksCell';
import { VersionsCell } from './GridCells/VersionsCell';
import { UpToDateCell } from './GridCells/UpToDateCell';

interface DataGridComponentProps {
    devices: DeviceWithSticks[];
}

export const isVersionTheSame = (collection: ActivationStick[], parentDeviceVersion: string) => {
    return collection.every((c) => c.systemVersion === parentDeviceVersion);
};

function exportToFile(devices: Device[]) {
    const formattedData = devices.map((device) => {
        return `${device.deviceSerial}\t${device.deviceId}`;
    });

    const fileContent = formattedData.join('\n');

    const blob = new Blob([fileContent], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'Devices.txt';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

function transformToTable(array: any[], numColumns: number) {
    const numRows = Math.ceil(array.length / numColumns);
    const table = [];

    for (let i = 0; i < numRows; i++) {
        const row = array.slice(i * numColumns, (i + 1) * numColumns);

        // If the last row is shorter than numColumns, pad it with null or a desired value
        while (row.length < numColumns) {
            row.push(null); // or another value such as 0 or an empty string
        }

        table.push(row);
    }

    return table;
}

function exportToPdf(devices: Device[]) {
    const doc = new jsPDF();
    const columns = 6;
    const rowsPerPage = 15; // Number of rows per page
    let currentPage = 1;

    const tableData = transformToTable(devices, columns);

    const addPageWithHeader = (doc: jsPDF, pageNum: number) => {
        if (pageNum > 1) {
            doc.addPage();
        }
        doc.setFontSize(11.8);
    };

    addPageWithHeader(doc, currentPage);

    let y = 23;
    let x = 10;
    const cellWidth = 31.7;
    const cellHeight = 17.5;
    let rowCount = 0;

    doc.setDrawColor(200, 200, 200);

    tableData.forEach((row) => {
        if (rowCount >= rowsPerPage) {
            currentPage++;
            addPageWithHeader(doc, currentPage);
            y = 23;
            rowCount = 0;
        }

        x = 10; // Reset x for each row
        row.forEach((cell) => {
            doc.rect(x, y - 8, cellWidth, cellHeight);
            if (cell) {
                const text = `${cell.deviceId}`;
                const textWidth = doc.getTextWidth(text);
                const textX = x + (cellWidth - textWidth - 3);
                doc.text(text, textX, y + 5.5);
            }
            x += cellWidth;
        });
        y += cellHeight;
        rowCount++;
    });

    doc.save('Devices.pdf');
}

function Toolbar() {
    const dispatch = useAppDispatch();
    const { userRoles } = useCognitoUser();
    const selectedRows = useAppSelector(selectSelectedOnboardDeviceTableRows);
    const onboardDevices = useAppSelector(selectOnboardDevices);
    const [isUpdateVersionDialogOpen, setIsUpdateVersionDialogOpen] = useState(false);
    const [getSecretsDialogOpen, setGetSecretsDialogOpen] = useState(false);
    const [attachOrganizationDialogOpen, setAttachOrganizationDialogOpen] = useState(false);
    const [createDevicesDialogOpen, setCreateDevicesDialogOpen] = useState(false);
    const [removeDevicesDialogOpen, setRemoveDevicesDialogOpen] = useState(false);

    const onVersionChange = () => {
        setIsUpdateVersionDialogOpen(true);
    };

    const onGetSecrets = () => {
        setGetSecretsDialogOpen(true);
    };

    const onAttachOrganization = () => {
        setAttachOrganizationDialogOpen(true);
    };

    const onCreateDevices = () => {
        setCreateDevicesDialogOpen(true);
    };

    const onRemoveDevices = () => {
        setRemoveDevicesDialogOpen(true);
    };

    const onReload = () => {
        dispatch(loadOnboardDevices());
        dispatch(loadActivationSticks());
        dispatch(loadSystemConfigurations());
    };

    const isAdmin = useMemo(() => {
        return userRoles && hasAllowedRole(userRoles, [UserRoles.ADMINS]);
    }, [userRoles]);

    return (
        <>
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                {isAdmin && (
                    <Button
                        startIcon={<UpgradeIcon />}
                        disabled={selectedRows.length === 0}
                        color="primary"
                        onClick={onVersionChange}
                    >
                        Change Version
                    </Button>
                )}
                <Button startIcon={<RefreshIcon />} color="primary" onClick={onReload}>
                    Reload
                </Button>
                <Button
                    disabled={selectedRows.length !== 1}
                    startIcon={<KeyIcon />}
                    color="primary"
                    onClick={onGetSecrets}
                >
                    Get Secrets
                </Button>
                {isAdmin && (
                    <Button
                        disabled={selectedRows.length === 0}
                        startIcon={<AddHomeIcon />}
                        color="primary"
                        onClick={onAttachOrganization}
                    >
                        Attach Organization
                    </Button>
                )}
                <Button startIcon={<AddIcon />} color="primary" onClick={onCreateDevices}>
                    Create Devices
                </Button>

                <Button
                    disabled={selectedRows.length === 0}
                    startIcon={<RemoveIcon />}
                    color="primary"
                    onClick={onRemoveDevices}
                >
                    Remove Devices
                </Button>

                <Button
                    startIcon={<AddIcon />}
                    color="primary"
                    disabled={selectedRows.length === 0}
                    onClick={() => {
                        const selectedDevices = selectedRows.map(
                            (id) => onboardDevices?.find((d) => d.deviceId == id) as Device,
                        );

                        exportToFile(selectedDevices);
                        exportToPdf(selectedDevices);
                    }}
                >
                    Export Ids
                </Button>
            </GridToolbarContainer>
            {isUpdateVersionDialogOpen && (
                <UpdateVersionDialog
                    selectedDeviceIds={selectedRows}
                    open={isUpdateVersionDialogOpen}
                    onClose={() => setIsUpdateVersionDialogOpen(false)}
                    onUpdateComplete={() => {
                        setIsUpdateVersionDialogOpen(false);
                        dispatch(loadOnboardDevices());
                    }}
                />
            )}
            {getSecretsDialogOpen && (
                <GetSecretsDialog
                    selectedDeviceId={selectedRows[0]}
                    open={getSecretsDialogOpen}
                    onClose={() => setGetSecretsDialogOpen(false)}
                />
            )}

            {attachOrganizationDialogOpen && (
                <AttachOrganizationDialog
                    selectedDeviceIds={selectedRows}
                    open={attachOrganizationDialogOpen}
                    onClose={() => setAttachOrganizationDialogOpen(false)}
                    onAttachComplete={() => {
                        setAttachOrganizationDialogOpen(false);
                        dispatch(loadOnboardDevices());
                    }}
                />
            )}

            {createDevicesDialogOpen && (
                <CreateDevicesDialog
                    open={createDevicesDialogOpen}
                    onClose={() => setCreateDevicesDialogOpen(false)}
                    onCreationCompleted={() => {
                        setCreateDevicesDialogOpen(false);
                        dispatch(loadOnboardDevices());
                    }}
                />
            )}

            {removeDevicesDialogOpen && (
                <RemoveDevicesDialog
                    open={removeDevicesDialogOpen}
                    selectedDeviceIds={selectedRows}
                    onClose={() => setRemoveDevicesDialogOpen(false)}
                    onRemovalCompleted={() => {
                        setRemoveDevicesDialogOpen(false);
                        dispatch(loadOnboardDevices());
                    }}
                />
            )}
        </>
    );
}

export const OnboardDevicesTableGrid = ({ devices }: DataGridComponentProps) => {
    const dispatch = useAppDispatch();
    const {
        sortModel,
        columnsConfig,
        columnsWidth,
        gridFilterModel,
        handleColumnVisibilityChange,
        handleColumnWidthChange,
        handleSortModelChange,
        handleFilterModelChange,
        onCellKeyDown,
        gridStateSynced,
    } = useManageGrid('devices/onboard');

    const selectedRows = useAppSelector(selectSelectedOnboardDeviceTableRows);

    const columns: GridColDef[] = useMemo(() => {
        return [
            {
                key: 'deviceSerial',
                type: 'number',
                filterOperators: getGridNumericOperators,
                renderCell: CopyTextCell,
            },
            { key: 'deviceId', renderCell: CopyTextCell },
            { key: 'organization', renderCell: OrganizationLabelCell },
            { key: 'status', renderCell: CopyTextCell },
            { key: 'systemVersion', renderCell: VersionsCell },
            {
                key: 'upToDate',
                renderCell: UpToDateCell,
            },
            {
                key: 'createdAt',
                valueFormatter: formatDate,
                filterOperators: getGridDateOperators,
            },
            {
                key: 'updatedAt',
                valueFormatter: formatDate,
                filterOperators: getGridDateOperators,
            },
            {
                key: 'activationSticksCount',
                type: 'number',
                filterOperators: getGridNumericOperators,
                renderCell: ActivationSticksCell,
            },
            { key: 'baseImageS3Link', renderCell: CopyTextCell },
            { key: 'commitHash', renderCell: CopyTextCell },
            { key: 'zymkeySerial', renderCell: CopyTextCell },
            { key: 'rpiSerial', renderCell: CopyTextCell },
            { key: 'username', renderCell: CopyTextCell },
            { key: 'hostname', renderCell: CopyTextCell },
            { key: 'sshPort', filterOperators: getGridNumericOperators, renderCell: CopyTextCell },
            { key: 'accessPointSSID', renderCell: CopyTextCell },
            { key: 'vpnProfile', renderCell: CopyTextCell },
            { key: 'vpnIPAddress', renderCell: CopyTextCell },
            { key: 'vpnPubkey', renderCell: CopyTextCell },
            { key: 'bundleDevicePubkey', renderCell: CopyTextCell },
            { key: 'bundleCloudPubkey', renderCell: CopyTextCell },
            { key: 'mapS3Link', renderCell: CopyTextCell },
            { key: 'zymkeyPubkey', renderCell: CopyTextCell },
            { key: 'deviceSSHPubkey', renderCell: CopyTextCell },
            { key: 'deviceSSHPubkeyType', renderCell: CopyTextCell },
            { key: 'deviceSSHPubkeyHash', renderCell: CopyTextCell },
        ].map((col) => {
            return {
                field: col.key,
                type: (col.type || 'string') as GridColType,
                headerName: transformKeyToTitle(col.key),
                renderCell: col.renderCell,
                valueFormatter: col.valueFormatter,
                width: columnsWidth[col.key] || 200,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                filterOperators: col.filterOperators ? col.filterOperators() : getGridStringOperators(),
            };
        });
    }, [columnsWidth]);

    const rows = devices.map((device) => {
        const row: any = {
            ...device,
            activationSticksCount: device.activationSticks.length,
            status: device.status || 'Unknown',
            id: device.deviceId,
        };

        row.organization = device.organization || 'None';

        if (device.systemVersion && isVersionTheSame(device.activationSticks, device.systemVersion)) {
            row.upToDate = VersionValidity.VALID;
        } else {
            row.upToDate = VersionValidity.NOT_VALID;
        }

        if (row.updatedAt) row.updatedAt = new Date(row.updatedAt);
        if (row.createdAt) row.createdAt = new Date(row.createdAt);

        return row;
    });

    const onRowSelectionModelChange = (rows: any[]) => {
        dispatch(setSelectedOnboardDeviceTableRows(rows));
    };

    return (
        <>
            {gridStateSynced && (
                <DataGrid
                    rows={rows}
                    columns={columns}
                    slots={{
                        toolbar: Toolbar as GridSlots['toolbar'],
                    }}
                    onRowSelectionModelChange={onRowSelectionModelChange}
                    rowSelectionModel={selectedRows}
                    checkboxSelection
                    disableRowSelectionOnClick
                    onCellKeyDown={onCellKeyDown}
                    filterModel={gridFilterModel}
                    sortModel={sortModel}
                    onSortModelChange={handleSortModelChange}
                    onColumnWidthChange={handleColumnWidthChange}
                    onColumnVisibilityModelChange={handleColumnVisibilityChange}
                    onFilterModelChange={handleFilterModelChange}
                    columnVisibilityModel={columnsConfig}
                />
            )}
        </>
    );
};
