import { useCallback, useState } from 'react';
import { throttle } from 'underscore';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import CircularProgress from '@mui/material/CircularProgress';
import { GeoJSON, Polygon, MultiPolygon } from 'geojson';

interface NominatimGeoJsonSearchProps {
    onChange: (value: { geoJson: Polygon | MultiPolygon; searchQuery: string }) => void;
}

export const NominatimGeoJsonSearch = ({ onChange }: NominatimGeoJsonSearchProps) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [searchQuery, setSearchQuery] = useState<string>('');

    const fetchSearchResults = useCallback(
        throttle(async (query: string) => {
            try {
                setLoading(true);
                const response = await axios.get(
                    `https://nominatim.openstreetmap.org/search?q=${query}&format=json&polygon_geojson=1`,
                );
                if (response.data && response.data[0]?.geojson) {
                    const geoJson = response.data[0].geojson as GeoJSON;
                    if (geoJson.type === 'Polygon' || geoJson.type === 'MultiPolygon') {
                        onChange({
                            searchQuery: query,
                            geoJson,
                        });
                    } else {
                        console.error('Unsupported result', geoJson);
                    }
                }
            } catch (error) {
                console.error('Error fetching search results:', error);
            } finally {
                setLoading(false);
            }
        }, 1000),
        [],
    );

    const handleSearchChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setSearchQuery(value);

        if (value.trim().length > 2) {
            await fetchSearchResults(value);
        }
    };

    return (
        <TextField
            value={searchQuery}
            onChange={handleSearchChange}
            fullWidth
            label="Пошук локацій"
            variant="outlined"
            size="small"
            placeholder="Введіть назву місця"
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        {loading && <CircularProgress size={20} />}
                    </InputAdornment>
                ),
            }}
        />
    );
};
