import { GridCellParams } from '@mui/x-data-grid';
import * as React from 'react';

export const ProcessingBuildModelsCell = (cellValues: GridCellParams) => {
    return (
        <span
            style={{
                fontFamily: 'ui-monospace, monospace',
            }}
        >
            {cellValues.row.models.join(', ')}
        </span>
    );
};
