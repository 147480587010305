import { useAppDispatch, useAppSelector } from '../store/hooks';
import { loadMapUploads, selectMapUploads } from '../store/maps.reducer';
import { useEffect } from 'react';
import { MapUploadsTableGrid } from '../Components/MapUploadsTableGrid';

export const MapUploads = () => {
    const dispatch = useAppDispatch();
    const mapUploads = useAppSelector(selectMapUploads);

    useEffect(() => {
        dispatch(loadMapUploads());
    }, []);

    return <>{mapUploads && <MapUploadsTableGrid />}</>;
};
