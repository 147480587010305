import React, { useMemo, useState } from 'react';
import {
    DataGrid,
    GridColDef,
    GridToolbarContainer,
    GridSlots,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
} from '@mui/x-data-grid';
import { Organization } from '../interfaces';
import { transformKeyToTitle } from '../utils/transformKeyToTitle';
import Button from '@mui/material/Button';
import { Add as AddIcon } from '@mui/icons-material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useAppDispatch } from '../store/hooks';
import { loadOrganizations } from '../store/organizations.reducer';
import { AddOrganizationDialog } from './AddOrganizationDialog';
import { useManageGrid } from '../hooks/useManageGrid';
import { OrganizationLabelCell } from './GridCells/OrganizationLabelCell';

interface DataGridComponentProps {
    organizations: Organization[];
}

function Toolbar() {
    const dispatch = useAppDispatch();

    const [addOrganizationDialogOpen, setAddOrganizationDialogOpen] = useState(false);

    const onAdd = () => {
        setAddOrganizationDialogOpen(true);
    };

    const onReload = () => {
        dispatch(loadOrganizations());
    };

    return (
        <>
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <Button startIcon={<RefreshIcon />} color="primary" onClick={onReload}>
                    Reload
                </Button>
                <Button startIcon={<AddIcon />} color="primary" onClick={onAdd}>
                    Add
                </Button>
            </GridToolbarContainer>
            {addOrganizationDialogOpen && (
                <AddOrganizationDialog
                    open={addOrganizationDialogOpen}
                    onClose={() => setAddOrganizationDialogOpen(false)}
                />
            )}
        </>
    );
}

export const OrganizationsTableGrid = ({ organizations }: DataGridComponentProps) => {
    const {
        sortModel,
        columnsConfig,
        columnsWidth,
        gridFilterModel,
        handleColumnVisibilityChange,
        handleColumnWidthChange,
        handleSortModelChange,
        handleFilterModelChange,
        onCellKeyDown,
        gridStateSynced,
    } = useManageGrid('organizations');

    const columns: GridColDef[] = useMemo(() => {
        return [{ key: 'name', renderCell: OrganizationLabelCell }].map((col) => {
            return {
                field: col.key,
                headerName: transformKeyToTitle(col.key),
                width: columnsWidth[col.key] || 200,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                filterable: true,
                renderCell: col.renderCell,
            };
        });
    }, [columnsWidth]);

    const rows = organizations.map((o) => {
        const row: any = {
            ...o,
            id: o.name,
        };

        return row;
    });

    return (
        <>
            {gridStateSynced && (
                <DataGrid
                    rows={rows}
                    columns={columns}
                    slots={{
                        toolbar: Toolbar as GridSlots['toolbar'],
                    }}
                    disableRowSelectionOnClick
                    sortModel={sortModel}
                    filterModel={gridFilterModel}
                    onSortModelChange={handleSortModelChange}
                    onColumnWidthChange={handleColumnWidthChange}
                    onColumnVisibilityModelChange={handleColumnVisibilityChange}
                    onFilterModelChange={handleFilterModelChange}
                    columnVisibilityModel={columnsConfig}
                    onCellKeyDown={onCellKeyDown}
                />
            )}
        </>
    );
};
