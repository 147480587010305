import axios from 'axios';
import config from '../config';
import { TileSourcesResult } from '../interfaces';
import { MultiPolygon, Polygon } from 'geojson';

interface RetrieveTilesSourcesParams {
    geoJson?: Polygon | MultiPolygon;
    mapUploadId?: string;
    processedMapName?: string;
    processedMapNames?: string[];
}

export const retrieveTilesSources = (params: RetrieveTilesSourcesParams): Promise<TileSourcesResult> => {
    const url = `${config.API_URL}/maps/tiles-sources`;

    return axios.post(url, params).then((response: any) => {
        return response.data.data;
    });
};
