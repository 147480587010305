import { LatLngBounds } from 'leaflet';
import { Polygon, MultiPolygon } from 'geojson';

export const getGeoJsonPolygonBounds = (geoJson: Polygon | MultiPolygon): LatLngBounds => {
    const bounds = new LatLngBounds([]);

    if (geoJson.type === 'Polygon') {
        geoJson.coordinates.forEach((ring) => {
            ring.forEach(([lng, lat]) => bounds.extend([lat, lng]));
        });
    } else if (geoJson.type === 'MultiPolygon') {
        geoJson.coordinates.forEach((polygon) => {
            polygon.forEach((ring) => {
                ring.forEach(([lng, lat]) => bounds.extend([lat, lng]));
            });
        });
    }

    return bounds;
};
