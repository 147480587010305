import { GridCellParams } from '@mui/x-data-grid';
import { Zone } from '../../interfaces';
import { IconButton, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ROUTES_CONFIGS } from '../../config';
import { navigateToTilesViewer } from '../SourceTilesViewer/navigateToTilesViewer';
import ZoomInMapIcon from '@mui/icons-material/ZoomInMap';
import { getZoneById } from '../../api/getZoneById';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

export const ZoneActionsCell = (cellValues: GridCellParams) => {
    const zone = cellValues.row as Zone;
    const navigate = useNavigate();

    return (
        <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
            <span
                style={{
                    marginRight: '5px',
                }}
            >
                <Tooltip title="Переглянути сорси зони" placement="top">
                    <IconButton
                        size="small"
                        onClick={async () => {
                            const zoneDetails = await getZoneById(zone._id);
                            navigateToTilesViewer({
                                geoJson: zoneDetails.geoJson,
                            });
                        }}
                        edge="end"
                        aria-label="delete"
                    >
                        <ZoomInMapIcon />
                    </IconButton>
                </Tooltip>
            </span>
            <span>
                <Tooltip title="Переглянути білди зони" placement="top">
                    <IconButton
                        size="small"
                        onClick={() => {
                            const url = `/app/maps/${ROUTES_CONFIGS.PROCESSING_BUILDS.path}?zoneId=${zone._id}`;
                            navigate(url);
                        }}
                        edge="end"
                    >
                        <CloudUploadIcon />
                    </IconButton>
                </Tooltip>
            </span>
        </div>
    );
};
