import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import * as React from 'react';
import List from '@mui/material/List';
import { useMemo } from 'react';
import { hasAllowedRole } from '../utils/hasAllowedRole';
import { UserRoles } from '../interfaces';
import { ROUTES_CONFIGS } from '../config';
import { useCognitoUser } from '../hooks/useCognitoUser';
import { useLocation, useNavigate } from 'react-router-dom';

export const SidebarList = () => {
    const { userRoles } = useCognitoUser();
    const location = useLocation();
    const navigate = useNavigate();

    const routes = useMemo(() => {
        if (userRoles && hasAllowedRole(userRoles, [UserRoles.ADMINS])) {
            return [
                ROUTES_CONFIGS.MAPS,
                ROUTES_CONFIGS.DEVICES,
                ROUTES_CONFIGS.CONFIGURATIONS,
                ROUTES_CONFIGS.ORGANIZATIONS,
            ];
        } else if (userRoles && hasAllowedRole(userRoles, [UserRoles.FARM])) {
            return [ROUTES_CONFIGS.DEVICES];
        }

        return [];
    }, userRoles);

    return (
        <List>
            {routes.map((route, index) => (
                <ListItem key={index} disablePadding>
                    <ListItemButton
                        onClick={() => {
                            navigate(route.path);
                        }}
                        selected={location.pathname.includes(route.path)}
                    >
                        <ListItemText primary={route.label} />
                    </ListItemButton>
                </ListItem>
            ))}
        </List>
    );
};
