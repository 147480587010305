import { useEffect } from 'react';
import { useMap } from 'react-leaflet';
import L from 'leaflet';
import { MultiPolygon, Polygon } from 'geojson';

interface MapSourcesRestrictionPolygonProps {
    geoJsonShape: Polygon | MultiPolygon;
}

const MapSourcesRestrictionPolygon = ({ geoJsonShape }: MapSourcesRestrictionPolygonProps) => {
    const map = useMap();

    useEffect(() => {
        let polygonLayer: any;
        if (geoJsonShape) {
            polygonLayer = L.geoJSON(geoJsonShape, {
                style: {
                    fillColor: '#778d31',
                    color: '#778d31',
                    weight: 2,
                    fillOpacity: 0.5,
                },
            }).addTo(map);
        }

        return () => {
            map.removeLayer(polygonLayer);
        };
    }, [map, geoJsonShape]);

    return null;
};

export default MapSourcesRestrictionPolygon;
