import { GridCellParams } from '@mui/x-data-grid';
import * as React from 'react';
import { ProcessingBuildStatusBadge } from '../ProcessingBuildStatusBadge';
import Box from '@mui/material/Box';

export const ProcessingStatusCell = (cellValues: GridCellParams) => {
    return (
        <Box
            sx={{
                display: 'flex',
                height: '100%',
                alignItems: 'center',
            }}
        >
            <ProcessingBuildStatusBadge processingBuild={cellValues.row} />
        </Box>
    );
};
