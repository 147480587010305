import axios from 'axios';
import config from '../config';
import { MultiPolygon, Polygon } from 'geojson';

export const createZone = (zone: { geoJson: Polygon | MultiPolygon; name: string; area: number }) => {
    const url = `${config.API_URL}/maps/zones`;

    return axios.post(url, zone).then((response: any) => {
        return response.data.data;
    });
};
