import axios from 'axios';
import config from '../config';
import { ZoneListItem } from '../interfaces';

interface Response {
    zones: ZoneListItem[];
}

export const getZones = (): Promise<Response> => {
    const url = `${config.API_URL}/maps/zones`;

    return axios.get(url).then((response: any) => {
        return response.data.data;
    });
};
