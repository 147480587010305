import L, {
    LatLngExpression,
    LatLngLiteral,
    LeafletMouseEvent,
    Map,
    Marker,
    Point as LeafletPoint,
} from 'leaflet';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useGetCurrentPosition } from '../hooks/useGetCurrentPosition';
import { useAppSelector } from '../store/hooks';
import { selectZones } from '../store/maps.reducer';
import { booleanPointInPolygon, point } from '@turf/turf';
import styled from 'styled-components';
import { throttle } from 'underscore';
import { useGetAvailableAreaPolygon } from '../hooks/useGetAvailableAreaPolygon';
import Box from '@mui/material/Box';
import { defaultMapCenter } from '../config';
import { Polygon, MultiPolygon, Point, LineString } from 'geojson';
import { getGeoJsonPolygonBounds } from '../utils/getGeoJsonPolygonBounds';
import { ShapeHistoryItem } from '../interfaces';

interface HoverInfo {
    point: LeafletPoint;
    isAvailable: boolean;
}

const TooltipBody = styled.div`
    display: flex;
    z-index: 1000;
    position: absolute;
    background-color: rgba(97, 97, 97, 0.92);
    border-radius: 4px;
    color: #fff;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    padding: 4px 8px;
    font-size: 0.6875rem;
    max-width: 300px;
    margin: 2px;
    word-wrap: break-word;
    font-weight: 500;
`;

interface MapPickerProps {
    selectedGeoJsonShape: Polygon | MultiPolygon | Point | LineString | null;
    addedShapesHistory: ShapeHistoryItem[];
    manualPolygonCoordinates: number[][];
    onManualPolygonChange: (LnglatPoint: number[]) => void;
}

export const MapPicker = ({
    selectedGeoJsonShape,
    onManualPolygonChange,
    addedShapesHistory,
    manualPolygonCoordinates,
}: MapPickerProps) => {
    const [hoverInfo, setHoverInfo] = useState<HoverInfo | null>(null);
    const zones = useAppSelector(selectZones);
    const mapRef = useRef<HTMLDivElement>(null);
    const [map, setMap] = useState<Map>();
    const { currentPosition } = useGetCurrentPosition();
    const polygonFeature = useGetAvailableAreaPolygon();

    useEffect(() => {
        if (
            map &&
            selectedGeoJsonShape &&
            (selectedGeoJsonShape.type === 'Polygon' || selectedGeoJsonShape.type === 'MultiPolygon') &&
            manualPolygonCoordinates.length === 0
        ) {
            const bounds = getGeoJsonPolygonBounds(selectedGeoJsonShape);

            map.fitBounds(bounds);
        }
    }, [selectedGeoJsonShape, map, manualPolygonCoordinates]);

    useEffect(() => {
        const map = L.map(mapRef.current!);
        L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
            subdomains: 'abcd',
            attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
            minZoom: 3,
            maxZoom: 18,
        }).addTo(map);
        map.setZoom(7);
        map.attributionControl.setPosition('bottomleft');
        map.zoomControl.setPosition('bottomright');
        setMap(map);

        return () => {
            map?.remove();
        };
    }, []);

    useEffect(() => {
        let availableSourcesLayer: any;
        if (map && polygonFeature) {
            availableSourcesLayer = L.geoJSON(polygonFeature);

            availableSourcesLayer
                .setStyle({
                    fillColor: '#778d31',
                    color: '#778d31',
                })
                .addTo(map);
        }

        return () => {
            if (map && polygonFeature && availableSourcesLayer) {
                map.removeLayer(availableSourcesLayer);
            }
        };
    }, [map, polygonFeature]);

    useEffect(() => {
        if (map) {
            map.setView(currentPosition || defaultMapCenter);
        }
    }, [map, currentPosition]);

    const isPointInArea = ({ lat, lng }: LatLngLiteral) => {
        const p = point([lng, lat]);
        return !!polygonFeature && booleanPointInPolygon(p, polygonFeature);
    };

    useEffect(() => {
        if (map) {
            map.on('click', (e: LeafletMouseEvent) => {
                const { lat, lng } = e.latlng;
                onManualPolygonChange([lng, lat]);
            });
        }

        return () => {
            map && map.off('click');
        };
    }, [map, onManualPolygonChange]);

    useEffect(() => {
        if (map) {
            const onHover = throttle((e: LeafletMouseEvent) => {
                setHoverInfo({
                    point: e.containerPoint,
                    isAvailable: isPointInArea(e.latlng),
                });
            }, 5);

            map.on('mousemove', onHover);
        }

        return () => {
            map && map.off('mousemove');
        };
    }, [map]);

    useEffect(() => {
        let historyLayers: any;
        if (map && addedShapesHistory.length > 0) {
            historyLayers = addedShapesHistory.map(({ geoJson }) => {
                return L.geoJSON(geoJson, {
                    style: {
                        fillColor: '#f94f4b',
                        color: '#f94f4b',
                    },
                }).addTo(map);
            });
        }

        return () => {
            if (map && historyLayers) {
                historyLayers.forEach((layer: any) => {
                    map.removeLayer(layer);
                });
            }
        };
    }, [map, addedShapesHistory]);

    useEffect(() => {
        let shapeLayer: any;
        let marker: Marker;

        if (map && selectedGeoJsonShape) {
            const drawTargetAreaMarker = (map: Map, targetPosition: LatLngExpression) => {
                const myIcon = L.icon({
                    iconUrl: 'https://mapnav-assets.s3.eu-central-1.amazonaws.com/marker.png',
                    iconSize: [60, 60],
                    iconAnchor: [30, 60],
                });

                return L.marker(targetPosition, {
                    icon: myIcon,
                }).addTo(map);
            };

            if (selectedGeoJsonShape && selectedGeoJsonShape.type === 'Point') {
                // Handle Point geometry
                const [lng, lat] = selectedGeoJsonShape.coordinates;
                shapeLayer = drawTargetAreaMarker(map, [lat, lng]);
            } else if (selectedGeoJsonShape && selectedGeoJsonShape.type === 'Polygon') {
                // Handle Polygon geometry
                shapeLayer = L.geoJSON(selectedGeoJsonShape, {
                    style: {
                        fillColor: '#f94f4b',
                        color: '#f94f4b',
                    },
                }).addTo(map);

                // Place a marker at the first coordinate of the first LinearRing
                const firstCoordinate = selectedGeoJsonShape.coordinates[0][0];
                marker = drawTargetAreaMarker(map, [firstCoordinate[1], firstCoordinate[0]]);
            } else if (selectedGeoJsonShape && selectedGeoJsonShape.type === 'LineString') {
                // Handle LineString geometry
                shapeLayer = L.geoJSON(selectedGeoJsonShape, {
                    style: {
                        color: '#f94f4b',
                        weight: 3,
                    },
                }).addTo(map);

                // Place a marker at the starting point of the LineString
                const firstCoordinate = selectedGeoJsonShape.coordinates[0];
                marker = drawTargetAreaMarker(map, [firstCoordinate[1], firstCoordinate[0]]);
            } else if (selectedGeoJsonShape && selectedGeoJsonShape.type === 'MultiPolygon') {
                // Render other GeoJSON shapes (fallback)
                shapeLayer = L.geoJSON(selectedGeoJsonShape, {
                    style: {
                        fillColor: '#f94f4b',
                        color: '#f94f4b',
                    },
                }).addTo(map);
                const firstCoordinate = selectedGeoJsonShape.coordinates[0][0][0];

                marker = drawTargetAreaMarker(map, [firstCoordinate[1], firstCoordinate[0]]);
            } else if (selectedGeoJsonShape) {
                console.log(`Shape type is not supported ${selectedGeoJsonShape}`);
            }
        }

        return () => {
            if (map && shapeLayer) {
                map.removeLayer(shapeLayer);
                marker?.removeFrom(map);
            }
        };
    }, [map, selectedGeoJsonShape]);

    useEffect(() => {
        mapRef.current!.addEventListener('mouseout', () => {
            setHoverInfo(null);
        });
    }, []);

    const tooltipText = useMemo(() => {
        if (hoverInfo) {
            return hoverInfo.isAvailable ? 'Натисніть щоб додати зону' : 'Не доступно';
        }

        return '';
    }, [hoverInfo, zones]);

    const tooltipStyle = useMemo(() => {
        if (hoverInfo) {
            return {
                display: 'flex',
                left: hoverInfo.point.x - (hoverInfo.isAvailable ? 80 : 50),
                top: hoverInfo.point.y - 40,
            };
        } else {
            return {
                display: 'none',
            };
        }
    }, [hoverInfo]);

    return (
        <Box
            sx={{
                height: '100%',
            }}
            className="flex-grow flex w-full relative"
            ref={mapRef}
        >
            <TooltipBody className="tooltip" style={tooltipStyle}>
                {tooltipText}
            </TooltipBody>
        </Box>
    );
};
