import { multiPolygon as TurfMultiPolygon, polygon as TurfPolygon } from '@turf/helpers';
import { MultiPolygon, Polygon } from 'geojson';

export const geoJsonPolygonToTurf = (geoJson: Polygon | MultiPolygon) => {
    let turfPolygon;

    // Handle different types of GeoJsonShape
    if (geoJson.type === 'Polygon') {
        turfPolygon = TurfPolygon(geoJson.coordinates);
    } else if (geoJson.type === 'MultiPolygon') {
        turfPolygon = TurfMultiPolygon(geoJson.coordinates);
    } else {
        console.error('Invalid geoJson provided', geoJson);
    }

    return turfPolygon;
};
