import { ProcessingBuildListItem, ProcessingBuildStatus } from '../interfaces';
import { CircularProgress, Tooltip } from '@mui/material';
import { Badge } from './Badge';

interface ProcessingBuildStatusBadgeProps {
    processingBuild: ProcessingBuildListItem;
}

const TooltipStatusText: any = {
    [ProcessingBuildStatus.COMPLETED]: 'Зона успішно створена.',
    [ProcessingBuildStatus.CREATED]: 'Сервер ініціалізує зону. Це може зайняти якийсь час.',
    [ProcessingBuildStatus.PROCESSING]: 'Сервер обробляє зону. Це може зайняти якийсь час.',
    [ProcessingBuildStatus.FAILED]: 'При створенні зони відбулася помилка.',
    [ProcessingBuildStatus.DEPRECATED]: 'Зону необхідно оновити до останньої версії.',
    [ProcessingBuildStatus.UPGRADING]: 'Сервер оновлює зону. Це може зайняти якийсь час.',
};

export const StatusColor: any = {
    [ProcessingBuildStatus.CREATED]: '#f9c84b',
    [ProcessingBuildStatus.PROCESSING]: '#f9a54b',
    [ProcessingBuildStatus.FAILED]: '#f94f4b',
    [ProcessingBuildStatus.COMPLETED]: '#458549',
    [ProcessingBuildStatus.DEPRECATED]: '#f94f4b',
    [ProcessingBuildStatus.UPGRADING]: '#f9a54b',
};

export const ProcessingBuildStatusBadge = ({ processingBuild }: ProcessingBuildStatusBadgeProps) => {
    const status = processingBuild.status;

    return (
        <Tooltip title={TooltipStatusText[status]} placement="top">
            <Badge backgroundColor={StatusColor[status]}>
                <>
                    {status}
                    {(status === ProcessingBuildStatus.CREATED ||
                        status === ProcessingBuildStatus.UPGRADING ||
                        status === ProcessingBuildStatus.PROCESSING) && (
                        <>
                            <span className="mr-2" />
                            <CircularProgress size={15} sx={{ color: 'white' }} />
                        </>
                    )}
                </>
            </Badge>
        </Tooltip>
    );
};
