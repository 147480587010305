import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { RootState } from './store';
import {
    ProcessingBuildListItem,
    ProcessedMapListItem,
    ZoneListItem,
    MapUploadListItem,
} from '../interfaces';
import { getZones } from '../api/getZones';
import { getProcessingBuilds } from '../api/getProcessingBuilds';
import { getProcessedMaps } from '../api/getProcessedMaps';
import { getMapUploads } from '../api/getMapUploads';

export interface MapsState {
    zones: ZoneListItem[] | null;
    mapUploads: MapUploadListItem[] | null;
    processingBuilds: ProcessingBuildListItem[] | null;
    processedMaps: ProcessedMapListItem[] | null;
}

const initialState: MapsState = {
    zones: null,
    mapUploads: null,
    processingBuilds: null,
    processedMaps: null,
};

const sortNewestFirst = (a: Date, b: Date) => b.getTime() - a.getTime();

export const loadZones = createAsyncThunk('maps/loadZones', async () => {
    const { zones } = await getZones();
    return zones.sort((a, b) => sortNewestFirst(new Date(a.createdAt), new Date(b.createdAt)));
});

export const loadMapUploads = createAsyncThunk('maps/loadMapUploads', async () => {
    const uploads = await getMapUploads();
    return uploads.sort((a, b) => sortNewestFirst(new Date(a.createdAt), new Date(b.createdAt)));
});

export const loadProcessingBuilds = createAsyncThunk('maps/loadProcessingBuilds', async () => {
    const [builds, processedMaps] = await Promise.all([getProcessingBuilds(), getProcessedMaps()]);
    builds.forEach((build) => {
        const processedMap = processedMaps.find((map) => map.buildId === build._id);
        if (processedMap) {
            build.processedMap = processedMap;
        }
    });

    return builds.sort((a, b) => sortNewestFirst(new Date(a.createdAt), new Date(b.createdAt)));
});

export const loadProcessedMaps = createAsyncThunk('maps/loadProcessedMaps', async () => {
    return await getProcessedMaps();
});

export const MapsSlice = createSlice({
    name: 'maps',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(loadZones.fulfilled, (state, action) => {
                state.zones = action.payload;
            })
            .addCase(loadZones.rejected, (state, action) => {
                console.error(action.error);
            })
            .addCase(loadProcessingBuilds.fulfilled, (state, action) => {
                state.processingBuilds = action.payload;
            })
            .addCase(loadProcessingBuilds.rejected, (state, action) => {
                console.error(action.error);
            })
            .addCase(loadMapUploads.fulfilled, (state, action) => {
                state.mapUploads = action.payload;
            })
            .addCase(loadMapUploads.rejected, (state, action) => {
                console.error(action.error);
            })
            .addCase(loadProcessedMaps.fulfilled, (state, action) => {
                state.processedMaps = action.payload;
            })
            .addCase(loadProcessedMaps.rejected, (state, action) => {
                console.error(action.error);
            });
    },
});

export const selectZones = (state: RootState) => state.maps.zones;
export const selectProcessingBuilds = (state: RootState) => state.maps.processingBuilds;
export const selectProcessedMaps = (state: RootState) => state.maps.processedMaps;
export const selectMapUploads = (state: RootState) => state.maps.mapUploads;

export default MapsSlice.reducer;
