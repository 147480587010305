import React, { useMemo, useState } from 'react';
import {
    DataGrid,
    GridColDef,
    GridToolbarContainer,
    GridSlots,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
} from '@mui/x-data-grid';
import { SystemConfiguration } from '../interfaces';
import { transformKeyToTitle } from '../utils/transformKeyToTitle';
import Button from '@mui/material/Button';
import { Add as AddIcon } from '@mui/icons-material';
import { AddSystemConfigurationDialog } from './AddSystemConfigurationDialog';
import RefreshIcon from '@mui/icons-material/Refresh';
import { loadSystemConfigurations } from '../store/devices.reducer';
import { useAppDispatch } from '../store/hooks';
import { useManageGrid } from '../hooks/useManageGrid';
import { CopyTextCell } from './GridCells/CopyTextCell';

interface DataGridComponentProps {
    configurations: SystemConfiguration[];
}

function Toolbar() {
    const dispatch = useAppDispatch();

    const [addConfigurationDialogOpen, setAddConfigurationDialogOpen] = useState(false);

    const onAdd = () => {
        setAddConfigurationDialogOpen(true);
    };

    const onReload = () => {
        dispatch(loadSystemConfigurations());
    };

    return (
        <>
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <Button startIcon={<RefreshIcon />} color="primary" onClick={onReload}>
                    Reload
                </Button>
                <Button startIcon={<AddIcon />} color="primary" onClick={onAdd}>
                    Add
                </Button>
            </GridToolbarContainer>
            {addConfigurationDialogOpen && (
                <AddSystemConfigurationDialog
                    open={addConfigurationDialogOpen}
                    onClose={() => setAddConfigurationDialogOpen(false)}
                />
            )}
        </>
    );
}

export const SystemConfigurationsTableGrid = ({ configurations }: DataGridComponentProps) => {
    const {
        sortModel,
        columnsConfig,
        columnsWidth,
        gridFilterModel,
        handleColumnVisibilityChange,
        handleColumnWidthChange,
        handleSortModelChange,
        handleFilterModelChange,
        onCellKeyDown,
        gridStateSynced,
    } = useManageGrid('systemConfiguration');

    const columns: GridColDef[] = useMemo(() => {
        return [
            { key: 'systemVersion' },
            { key: 'applicationVersion' },
            { key: 'mapVersion' },
            { key: 'containerVersion' },
        ].map((col) => {
            return {
                field: col.key,
                headerName: transformKeyToTitle(col.key),
                width: columnsWidth[col.key] || 200,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                filterable: true,
                renderCell: CopyTextCell,
            };
        });
    }, [columnsWidth]);

    const rows = configurations.map((c) => {
        const row: any = {
            systemVersion: c.systemVersion,
            ...c.configuration,
            id: c.systemVersion,
        };

        return row;
    });

    return (
        <>
            {gridStateSynced && (
                <DataGrid
                    rows={rows}
                    columns={columns}
                    slots={{
                        toolbar: Toolbar as GridSlots['toolbar'],
                    }}
                    disableRowSelectionOnClick
                    sortModel={sortModel}
                    filterModel={gridFilterModel}
                    onSortModelChange={handleSortModelChange}
                    onColumnWidthChange={handleColumnWidthChange}
                    onColumnVisibilityModelChange={handleColumnVisibilityChange}
                    onFilterModelChange={handleFilterModelChange}
                    columnVisibilityModel={columnsConfig}
                    onCellKeyDown={onCellKeyDown}
                />
            )}
        </>
    );
};
