import { useState } from 'react';
import { DeviceWithSticks } from '../../interfaces';
import { GridCellParams } from '@mui/x-data-grid';
import { ActivationSticksDialog } from '../ActivationSticksDialog';

export const ActivationSticksCell = (cellValues: GridCellParams) => {
    const [selectedDevice, setSelectedDevice] = useState<DeviceWithSticks | null>(null);
    const [isActivationSticksDialogOpen, setIsActivationSticksDialogOpen] = useState<boolean>(false);

    const handleActivationSticksDialogClose = () => {
        setSelectedDevice(null);
        setIsActivationSticksDialogOpen(false);
    };

    return (
        <>
            <div
                style={{ fontWeight: 500, cursor: cellValues.value !== 0 ? 'pointer' : '' }}
                onClick={() => {
                    if (!cellValues.value) {
                        return;
                    }
                    setSelectedDevice(cellValues.row);
                    setIsActivationSticksDialogOpen(true);
                }}
            >
                <span>{cellValues.value as number}</span>
            </div>

            {isActivationSticksDialogOpen && selectedDevice && (
                <ActivationSticksDialog
                    device={selectedDevice}
                    open={isActivationSticksDialogOpen}
                    onClose={handleActivationSticksDialogClose}
                />
            )}
        </>
    );
};
