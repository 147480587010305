import { useAppDispatch, useAppSelector } from '../store/hooks';
import { loadProcessingBuilds, loadZones, selectProcessingBuilds } from '../store/maps.reducer';
import { useEffect } from 'react';
import { ProcessingBuildsTableGrid } from '../Components/ProcessingBuildsTableGrid';

export const MapProcessingBuilds = () => {
    const dispatch = useAppDispatch();
    const processingBuilds = useAppSelector(selectProcessingBuilds);

    useEffect(() => {
        dispatch(loadProcessingBuilds());
        dispatch(loadZones());

        const timer = setInterval(() => {
            dispatch(loadProcessingBuilds());
        }, 5000);

        return () => {
            clearTimeout(timer);
        };
    }, []);

    return <>{processingBuilds && <ProcessingBuildsTableGrid />}</>;
};
