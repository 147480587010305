import { MapUploadListItem, MapUploadStatus } from '../interfaces';
import { CircularProgress, Tooltip } from '@mui/material';
import { Badge } from './Badge';

interface ProcessingBuildStatusBadgeProps {
    mapUpload: MapUploadListItem;
}

const TooltipStatusText: any = {
    [MapUploadStatus.COMPLETED]: 'Карта успішно завантажена.',
    [MapUploadStatus.CLIENT_UPLOAD]: 'Сорси зони завантажуються.',
    [MapUploadStatus.PROCESSING]: 'Синхронізація з базою данних.',
};

export const StatusColor: any = {
    [MapUploadStatus.CLIENT_UPLOAD]: '#f9a54b',
    [MapUploadStatus.PROCESSING]: '#f9a54b',
    [MapUploadStatus.COMPLETED]: '#458549',
};

export const MapUploadStatusBadge = ({ mapUpload }: ProcessingBuildStatusBadgeProps) => {
    const status = mapUpload.status;

    return (
        <Tooltip title={TooltipStatusText[status]} placement="top">
            <Badge backgroundColor={StatusColor[status]}>
                <>
                    {status}
                    {(status === MapUploadStatus.CLIENT_UPLOAD || status === MapUploadStatus.PROCESSING) && (
                        <>
                            <span className="mr-2" />
                            <CircularProgress size={15} sx={{ color: 'white' }} />
                        </>
                    )}
                </>
            </Badge>
        </Tooltip>
    );
};
