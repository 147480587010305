import { LatLngExpression } from 'leaflet';

interface Config {
    API_URL: string;
    S3_DATA: {
        TilesBucketName: string;
        TilesBucketRegion: string;
    };
    POOL_DATA: {
        UserPoolId: string;
        ClientId: string;
        IdentityPoolId: string;
    };
}

const config: Config = {
    API_URL: process.env.REACT_APP_API_URL as string,
    S3_DATA: {
        TilesBucketName: process.env.REACT_APP_TILES_BUCKET_NAME as string,
        TilesBucketRegion: process.env.REACT_APP_TILES_BUCKET_REGION as string,
    },
    POOL_DATA: {
        UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID as string,
        ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID as string,
        IdentityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID as string,
    },
};

export default config;

export const ROUTES_CONFIGS = {
    MAPS: {
        label: 'Карти',
        path: 'maps',
    },
    ZONES: {
        label: 'Зони',
        path: 'zones',
    },
    PROCESSING_BUILDS: {
        label: 'Білди',
        path: 'processing-builds',
    },
    MAP_UPLOADS: {
        label: 'Завантаження карт',
        path: 'map-uploads',
    },
    DEVICES: {
        label: 'Борти',
        path: 'devices',
    },
    CONFIGURATIONS: {
        label: 'Конфігурації оновлення',
        path: 'configurations',
    },
    ORGANIZATIONS: {
        label: 'Організації',
        path: 'organizations',
    },
};

export const defaultMapCenter: LatLngExpression = [50.45287861836015, 30.51755710359059]; // Kiev;
