import Box from '@mui/material/Box';
import * as React from 'react';
import { Tab, Tabs } from '@mui/material';
import { useCognitoUser } from '../hooks/useCognitoUser';
import { useEffect, useMemo, useState } from 'react';
import { hasAllowedRole } from '../utils/hasAllowedRole';
import { ROUTES_CONFIGS } from '../config';
import { UserRoles } from '../interfaces';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { Zones } from './Zones';
import { MapProcessingBuilds } from './MapProcessingBuilds';
import { MapUploads } from './MapUploads';

export const Maps = () => {
    const [tabValue, setTabValue] = useState<number | null>(0);
    const { userRoles } = useCognitoUser();
    const navigate = useNavigate();

    const routes = useMemo(() => {
        if (userRoles && hasAllowedRole(userRoles, [UserRoles.ADMINS])) {
            return [ROUTES_CONFIGS.ZONES, ROUTES_CONFIGS.PROCESSING_BUILDS, ROUTES_CONFIGS.MAP_UPLOADS];
        }

        return [];
    }, userRoles);

    useEffect(() => {
        const activeTabIndex = routes.findIndex((route) => location.pathname.includes(route.path));
        if (activeTabIndex !== -1) {
            setTabValue(activeTabIndex);
        }
    }, [location.pathname, routes]);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
        navigate(`/app/maps/${routes[newValue].path}`);
    };

    return (
        <>
            <Box sx={{ borderBottom: 1, p: 2, pb: 0, borderColor: 'divider' }}>
                <Tabs value={tabValue} onChange={handleChange}>
                    {routes.map((route) => (
                        <Tab label={route.label} key={route.path} />
                    ))}
                </Tabs>
            </Box>
            <Box sx={{ p: 2, height: 'calc(100vh - 65px)' }}>
                <Routes>
                    <Route path="zones" element={<Zones />} />
                    <Route path="processing-builds" element={<MapProcessingBuilds />} />
                    <Route path="map-uploads" element={<MapUploads />} />
                    <Route path="*" element={<Navigate to="zones" replace />} />
                </Routes>
            </Box>
        </>
    );
};
