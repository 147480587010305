import Box from '@mui/material/Box';

import React, { useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import {
    loadActivationSticks,
    loadOnboardDevices,
    loadSystemConfigurations,
    selectActivationSticks,
    selectOnboardDevices,
} from '../store/devices.reducer';
import { OnboardDevicesTableGrid } from '../Components/OnboardDevicesTableGrid';
import { Device, DeviceWithSticks } from '../interfaces';

export const Devices = () => {
    const dispatch = useAppDispatch();
    const onboardDevices = useAppSelector(selectOnboardDevices);
    const activationSticks = useAppSelector(selectActivationSticks);

    useEffect(() => {
        dispatch(loadOnboardDevices());
        dispatch(loadActivationSticks());
        dispatch(loadSystemConfigurations());
    }, []);

    const onboardDevicesWithSticks: DeviceWithSticks[] | null = useMemo(() => {
        if (onboardDevices && activationSticks) {
            return onboardDevices.map((device: Device) => ({
                ...device,
                activationSticks: activationSticks.filter((stick) => stick.deviceId === device.deviceId),
            }));
        } else {
            return null;
        }
    }, [onboardDevices, activationSticks]);

    return (
        <Box sx={{ width: '100%', padding: '20px', height: '100vh' }}>
            {onboardDevicesWithSticks && <OnboardDevicesTableGrid devices={onboardDevicesWithSticks} />}
        </Box>
    );
};
