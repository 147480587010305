import { useEffect, useState } from 'react';
import { GridFilterModel, useGridApiRef } from '@mui/x-data-grid';

export interface ManageGridOptions {
    filterModel?: GridFilterModel;
}

export const useManageGrid = (gridName: string, options?: ManageGridOptions) => {
    const apiRef = useGridApiRef();

    const [columnsConfig, setColumnsConfig] = useState<any>({});
    const [columnsWidth, setColumnsWidth] = useState<any>({});
    const [sortModel, setSortModel] = useState<any>([]);
    const [gridFilterModel, setGridFilterModel] = useState<any>();
    const [gridStateSynced, setGridStateSynced] = useState<boolean>(false);

    const columnsConfigName = `${gridName}-TableColumnsConfig`;
    const columnsColumnsWidthName = `${gridName}-TableColumnsWidth`;
    const sortModelName = `${gridName}-TableSortModel`;
    const filterModelName = `${gridName}-TableFilterModel`;

    useEffect(() => {
        const initialColumnsConfig = localStorage.getItem(columnsConfigName);
        initialColumnsConfig && setColumnsConfig(JSON.parse(initialColumnsConfig));

        const initialColumnsWidth = localStorage.getItem(columnsColumnsWidthName);
        initialColumnsWidth && setColumnsWidth(JSON.parse(initialColumnsWidth));

        const initialSortModel = localStorage.getItem(sortModelName);
        initialSortModel && setSortModel(JSON.parse(initialSortModel));

        const gridFilterModelJson = localStorage.getItem(filterModelName);
        let gridFilterModel: GridFilterModel = gridFilterModelJson
            ? JSON.parse(gridFilterModelJson)
            : { items: [] };
        if (options && options.filterModel) {
            gridFilterModel = {
                ...gridFilterModel,
                ...options.filterModel,
            };
        }
        gridFilterModel && setGridFilterModel(gridFilterModel);

        setGridStateSynced(true);
    }, []);

    const handleColumnVisibilityChange = (newConfig: any) => {
        newConfig.__check__ = true; // newer hide
        setColumnsConfig(newConfig);
        const jsonData = JSON.stringify(newConfig);
        localStorage.setItem(columnsConfigName, jsonData);
    };

    const handleColumnWidthChange = (params: any) => {
        const field = params.colDef.field;
        const width = params.width;

        const storedWidths = localStorage.getItem(columnsColumnsWidthName);
        const storedWidthsObj = storedWidths ? JSON.parse(storedWidths) : {};

        const updatedWidths = {
            ...storedWidthsObj,
            [field]: width,
        };

        localStorage.setItem(columnsColumnsWidthName, JSON.stringify(updatedWidths));
    };

    const handleSortModelChange = (newSortModel: any) => {
        setSortModel(newSortModel);
        localStorage.setItem(sortModelName, JSON.stringify(newSortModel));
    };

    const handleFilterModelChange = (model: GridFilterModel) => {
        setGridFilterModel(model);
        localStorage.setItem(filterModelName, JSON.stringify(model));
    };

    const onCellKeyDown = (cell: any, event: any) => {
        // Override row selection
        if ((event.ctrlKey || event.metaKey) && event.key === 'c') {
            navigator.clipboard
                .writeText((cell.value || '').toString())
                .catch((err) => console.error('Failed to copy text: ', err));
        }
    };

    const getViewRows = () => {
        // Check for API existence for a newer version
        if (
            apiRef.current &&
            apiRef.current.getSortedRows &&
            apiRef.current.store &&
            apiRef.current.store.getSnapshot
        ) {
            const rows = apiRef.current.getSortedRows();
            const filteredRowsLookup = apiRef.current.store.getSnapshot().filter?.filteredRowsLookup;
            if (filteredRowsLookup) {
                const trueKeys = Object.keys(filteredRowsLookup).filter(
                    (key) => filteredRowsLookup[key] === true,
                );
                return rows.filter((row) => trueKeys.indexOf(row._id.toString()) != -1);
            }
        }
    };

    return {
        apiRef,
        sortModel,
        gridFilterModel,
        columnsConfig,
        columnsWidth,
        handleColumnVisibilityChange,
        handleColumnWidthChange,
        handleFilterModelChange,
        handleSortModelChange,
        onCellKeyDown,
        getViewRows,
        gridStateSynced,
    };
};
