import axios from 'axios';
import config from '../config';
import { ProcessingBuildRequestProps } from '../interfaces';

export const createProcessingBuild = (zone: ProcessingBuildRequestProps) => {
    const url = `${config.API_URL}/maps/processing-builds`;

    return axios.post(url, zone).then((response: any) => {
        return response.data.data;
    });
};
