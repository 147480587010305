import { GridCellParams } from '@mui/x-data-grid';
import React, { useMemo } from 'react';

export const OrganizationLabelCell = (cellValues: GridCellParams) => {
    const labelColor = useMemo(() => {
        return cellValues.row.organizationDetails?.labelColor || '#5a5b5d';
    }, [cellValues]);

    return (
        <div>
            <span
                style={{
                    backgroundColor: labelColor,
                    color: '#fff',
                    padding: '6px 10px',
                    borderRadius: '15px',
                }}
            >
                {cellValues.value as string}
            </span>
        </div>
    );
};
