import { GridCellParams } from '@mui/x-data-grid';
import { IconButton, Tooltip } from '@mui/material';
import * as React from 'react';
import { navigateToTilesViewer } from '../SourceTilesViewer/navigateToTilesViewer';
import ZoomInMapIcon from '@mui/icons-material/ZoomInMap';
import DownloadIcon from '@mui/icons-material/Download';
import { getProcessedMapDownloadLink } from '../../api/getProcessedMapDownloadLink';
import { downloadFileFromUrl } from '../../utils/downloadFileFromUrl';
import { ProcessingBuildListItem } from '../../interfaces';

export const ProcessedBuildActionsCell = (cellValues: GridCellParams) => {
    const processedMap = (cellValues.row as ProcessingBuildListItem).processedMap;

    return (
        <>
            {processedMap && (
                <>
                    <span
                        style={{
                            marginRight: '5px',
                        }}
                    >
                        <Tooltip title="Переглянути сорси зони" placement="top">
                            <IconButton
                                size="small"
                                onClick={async () => {
                                    navigateToTilesViewer({
                                        processedMapName: processedMap.name,
                                    });
                                }}
                                edge="end"
                                aria-label="delete"
                            >
                                <ZoomInMapIcon />
                            </IconButton>
                        </Tooltip>
                    </span>

                    <span>
                        <Tooltip title="Скачати карти" placement="top">
                            <IconButton
                                size="small"
                                onClick={async () => {
                                    const presignedUrl = await getProcessedMapDownloadLink(processedMap._id);
                                    const keyParts = processedMap.uploadKey.split('/');
                                    await downloadFileFromUrl(presignedUrl, keyParts[keyParts.length - 1]);
                                }}
                                edge="end"
                                aria-label="delete"
                            >
                                <DownloadIcon />
                            </IconButton>
                        </Tooltip>
                    </span>
                </>
            )}
        </>
    );
};
