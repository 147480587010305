import React, { useEffect, useState } from 'react';
import { LatLng, LatLngBounds } from 'leaflet';
import Box from '@mui/material/Box';
import SourceTilesMap from './SourceTilesMap';
import StreetViewWidget from './StreetViewWidget';
import { Polygon, MultiPolygon } from 'geojson';
import { getGeoJsonPolygonCenter } from '../../utils/getGeoJsonPolygonCenter';

interface SourceTilesViewerProps {
    tilesSourcesMap: Map<string, string>;
    geoJsonShape: Polygon | MultiPolygon;
}

const SourceTilesViewer = ({ tilesSourcesMap, geoJsonShape }: SourceTilesViewerProps) => {
    const [bounds, setBounds] = useState<LatLngBounds | null>(null);
    const onBoundsChange = (bounds: LatLngBounds) => {
        setBounds(bounds);
    };

    const [center, setCenter] = useState<LatLng | null>(null);

    useEffect(() => {
        if (geoJsonShape) {
            const { lat, lng } = getGeoJsonPolygonCenter(geoJsonShape);
            setCenter(new LatLng(lat, lng));
        }
    }, [geoJsonShape]);

    return (
        <Box sx={{ position: 'relative', width: '100%', height: '100vh' }}>
            {center && (
                <SourceTilesMap
                    onBoundsChange={onBoundsChange}
                    bounds={bounds}
                    tilesSourcesMap={tilesSourcesMap}
                    center={center}
                    zoom={15}
                />
            )}
            {bounds && center && (
                <StreetViewWidget
                    bounds={bounds}
                    geoJsonShape={geoJsonShape}
                    center={center}
                    zoom={10}
                    onBoundsChange={onBoundsChange}
                />
            )}
        </Box>
    );
};

export default SourceTilesViewer;
