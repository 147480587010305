import React, { useEffect, useState } from 'react';
import { retrieveTilesSources } from '../api/retrieveTilesSources';
import { ToastType } from '../interfaces';
import SourceTilesViewer from '../Components/SourceTilesViewer/SourceTilesViewer';
import { getTilesSourcesMap } from '../Components/SourceTilesViewer/getTilesSourcesMap';
import { getTilesSourcesData } from '../api/getTilesSourcesData';
import { useToastMessage } from '../hooks/useToastMessage';
import ToastWrapper from '../Components/ToastWrapper';
import { useLocation } from 'react-router-dom';
import { Polygon, MultiPolygon } from 'geojson';
import { mergeGeoJsonShapesIntoMultiPolygon } from '../utils/mergeGeoJsonShapesIntoMultiPolygon';

const TilesViewer = () => {
    const { isOpened, toastMessage, setToastMessage, handleCloseToast } = useToastMessage();
    const location = useLocation();

    const [geoJsonShape, setGeoGsonShape] = useState<Polygon | MultiPolygon>();
    const [tilesSourcesMap, setTilesSourcesMap] = useState<Map<string, string> | null>(null);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const geoJsonKey = searchParams.get('geoJsonKey');
        const eventNameParam = searchParams.get('processedMapName');
        const eventNamesParam = searchParams.get('processedMapNames');
        const mapUploadId = searchParams.get('mapUploadId');

        if (!geoJsonKey && !eventNameParam && !eventNamesParam && !mapUploadId) {
            console.log(
                'geoJsonKey, processedMapName, or processedMapNames or mapUploadId should be provided in the URL',
            );
            setToastMessage({
                message: `Не вказана зона в url.`,
                type: ToastType.WARNING,
            });
        }

        (async () => {
            try {
                const geoJson = geoJsonKey ? JSON.parse(localStorage.getItem(geoJsonKey) || '{}') : undefined;
                const { presigned_url, geoJson: geoJsonResult } = await retrieveTilesSources({
                    geoJson,
                    mapUploadId: mapUploadId ? decodeURIComponent(mapUploadId) : undefined,
                    processedMapName: eventNameParam ? decodeURIComponent(eventNameParam) : undefined,
                    processedMapNames: eventNamesParam
                        ? JSON.parse(decodeURIComponent(eventNamesParam))
                        : undefined,
                });
                const shape =
                    geoJsonResult instanceof Array
                        ? mergeGeoJsonShapesIntoMultiPolygon(geoJsonResult)
                        : geoJsonResult;
                const response = await getTilesSourcesData(presigned_url);
                setTilesSourcesMap(getTilesSourcesMap(response));
                setGeoGsonShape(shape);
            } catch (e) {
                console.error(e);
                setToastMessage({
                    message: `Не вдалося отримати тайлів.`,
                    type: ToastType.WARNING,
                });
            }
        })();
    }, [location.search]);

    return (
        <>
            {tilesSourcesMap && geoJsonShape ? (
                <SourceTilesViewer tilesSourcesMap={tilesSourcesMap} geoJsonShape={geoJsonShape} />
            ) : (
                <>Getting tiles list... </>
            )}
            <ToastWrapper
                open={isOpened}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                autoHideDuration={3000}
                onClose={handleCloseToast}
                message={toastMessage?.message}
                type={toastMessage?.type}
            />
        </>
    );
};

export default TilesViewer;
