import Box from '@mui/material/Box';
import React, { useEffect } from 'react';
import { loadSystemConfigurations, selectSystemConfigurations } from '../store/devices.reducer';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { SystemConfigurationsTableGrid } from '../Components/SystemConfigurationsTableGrid';

export const SystemConfigurations = () => {
    const dispatch = useAppDispatch();
    const systemConfigurations = useAppSelector(selectSystemConfigurations);

    useEffect(() => {
        dispatch(loadSystemConfigurations());
    }, []);

    return (
        <Box sx={{ width: '100%', padding: '20px', height: '100vh' }}>
            {systemConfigurations && <SystemConfigurationsTableGrid configurations={systemConfigurations} />}
        </Box>
    );
};
