import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    CircularProgress,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { MapPolygonPreviewer } from './MapPolygonPreviewer';
import Box from '@mui/material/Box';
import { MapEntity, MapEntityWithGeometry } from '../interfaces';

interface Props {
    open: boolean;
    list: MapEntity[];
    selectedRow: MapEntity;
    getEntity: (id: string) => Promise<MapEntityWithGeometry>;
    onSelectedRowChange: (row: MapEntity) => void;
    onClose: () => void;
}

export const SelectedPolygonDialog = ({
    list,
    selectedRow,
    getEntity,
    onSelectedRowChange,
    open,
    onClose,
}: Props) => {
    const [selectedEntity, setSelectedEntity] = useState<MapEntityWithGeometry | null>(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchEntity = async () => {
            setLoading(true);
            try {
                const entity = await getEntity(selectedRow._id);
                setSelectedEntity(entity);
            } catch (error) {
                console.error('Failed to fetch entity:', error);
            } finally {
                setLoading(false);
            }
        };

        if (selectedRow) {
            fetchEntity();
        }
    }, [selectedRow, getEntity]);

    return (
        <Dialog fullScreen onClose={onClose} open={open}>
            <DialogTitle
                sx={{
                    display: 'flex',
                    justifyContent: 'right',
                }}
            >
                <CloseIcon sx={{ cursor: 'pointer' }} onClick={onClose} />
            </DialogTitle>
            <DialogContent>
                <Box
                    sx={{
                        display: 'flex',
                    }}
                >
                    {/* Styled List */}
                    <Box
                        sx={{
                            minWidth: 250,
                            maxHeight: 'calc(100vh - 80px)',
                            overflowY: 'auto',
                        }}
                    >
                        <List>
                            {list.map((item) => (
                                <ListItem key={item.name} disablePadding>
                                    <ListItemButton
                                        selected={selectedRow.name === item.name}
                                        onClick={() => onSelectedRowChange(item)}
                                    >
                                        <ListItemText primary={item.name} />
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List>
                    </Box>

                    {/* Map Previewer */}
                    <Box
                        sx={{
                            marginLeft: 3,
                            height: 'calc(100vh - 80px)',
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        {loading ? (
                            <CircularProgress />
                        ) : selectedEntity ? (
                            <MapPolygonPreviewer key={selectedEntity.name} geoJson={selectedEntity.geoJson} />
                        ) : (
                            <Box>No data available</Box>
                        )}
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    );
};
