import * as React from 'react';
import Toolbar from '@mui/material/Toolbar';
import { Maps } from '../routes/Maps';
import { useCognitoUser } from '../hooks/useCognitoUser';
import { Navigate, Route, Routes } from 'react-router-dom';
import Box from '@mui/material/Box';
import { Devices } from '../routes/Devices';
import { SystemConfigurations } from '../routes/SystemConfigurations';
import { ProtectedRoute } from './ProtectedRoute';
import { UserRoles } from '../interfaces';
import { Organizations } from '../routes/Organizations';
import Drawer from '@mui/material/Drawer';
import { Logout } from './Logout';
import Divider from '@mui/material/Divider';
import { SidebarList } from './SidebarList';

const drawerWidth = 240;

export default function Layout() {
    const { userRoles, user } = useCognitoUser();

    return (
        <Box sx={{ display: 'flex' }}>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}
                variant="permanent"
                anchor="left"
            >
                <Toolbar>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-end',
                            width: '100%',
                        }}
                    >
                        <Logout />
                    </Box>
                </Toolbar>
                <Divider />
                <SidebarList />
            </Drawer>

            <Box
                component="main"
                sx={{
                    bgcolor: 'background.default',
                    width: `calc(100% - ${drawerWidth}px)`,
                }}
            >
                {user && userRoles ? (
                    <Routes>
                        <Route
                            path="maps/*"
                            element={
                                <ProtectedRoute allowedRoles={[UserRoles.ADMINS]}>
                                    <Maps />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="devices"
                            element={
                                <ProtectedRoute allowedRoles={[UserRoles.ADMINS, UserRoles.FARM]}>
                                    <Devices />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="configurations"
                            element={
                                <ProtectedRoute allowedRoles={[UserRoles.ADMINS]}>
                                    <SystemConfigurations />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="organizations"
                            element={
                                <ProtectedRoute allowedRoles={[UserRoles.ADMINS]}>
                                    <Organizations />
                                </ProtectedRoute>
                            }
                        />
                        <Route path="*" element={<Navigate to="devices" replace />} />
                    </Routes>
                ) : (
                    <></>
                )}
            </Box>
        </Box>
    );
}
