import axios from 'axios';
import config from '../config';
import { ProcessingBuildListItem } from '../interfaces';

export const getProcessingBuilds = (): Promise<ProcessingBuildListItem[]> => {
    const url = `${config.API_URL}/maps/processing-builds`;

    return axios.get(url).then((response: any) => {
        return response.data.data.builds;
    });
};
