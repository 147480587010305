import { multiPolygon } from '@turf/turf';
import { useAppSelector } from '../store/hooks';
import { selectMapUploads } from '../store/maps.reducer';
import { mergeGeoJsonShapesIntoMultiPolygon } from '../utils/mergeGeoJsonShapesIntoMultiPolygon';
import { useMemo } from 'react';

export const useGetAvailableAreaPolygon = () => {
    let mapUploads = useAppSelector(selectMapUploads);

    return useMemo(() => {
        if (!mapUploads) {
            return null;
        }

        mapUploads = mapUploads.filter((upload) => upload.status === 'COMPLETED');

        if (mapUploads.length === 0) {
            return null;
        }

        const combinedShape = mergeGeoJsonShapesIntoMultiPolygon(mapUploads.map((upload) => upload.geoJson));
        return multiPolygon(combinedShape.coordinates);
    }, [mapUploads]);
};
