import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { MapPicker } from './MapPicker';
import Box from '@mui/material/Box';
import { NewZone } from './NewZone';
import { Point, LineString, MultiPolygon, Polygon } from 'geojson';
import { ShapeHistoryItem } from '../interfaces';

interface Props {
    open: boolean;
    onClose: () => void;
    onAdd: () => void;
}

const defaultPolygonName = 'Нова зона';

export const AddZoneDialog = ({ open, onClose, onAdd }: Props) => {
    const [newPolygonName, setNewPolygonName] = useState<string>(defaultPolygonName);
    const [addedShapesHistory, setAddedShapesHistory] = useState<ShapeHistoryItem[]>([]);
    const [selectedGeoJsonShape, setSelectedGeoJsonShape] = useState<
        Polygon | MultiPolygon | Point | LineString | null
    >(null);
    const [manualPolygonCoordinates, setManualPolygonCoordinates] = useState<number[][]>([]);

    useEffect(() => {
        if (manualPolygonCoordinates.length === 1) {
            // Case 1: Render a single point
            const pointFeature: Point = {
                type: 'Point',
                coordinates: manualPolygonCoordinates[0],
            };
            setSelectedGeoJsonShape(pointFeature);
        } else if (manualPolygonCoordinates.length === 2) {
            // Case 2: Render a line between two points
            const lineFeature: LineString = {
                type: 'LineString',
                coordinates: manualPolygonCoordinates,
            };
            setSelectedGeoJsonShape(lineFeature);
        } else if (manualPolygonCoordinates.length >= 3) {
            // Case 3: Render a polygon
            const closedCoordinates = [...manualPolygonCoordinates, manualPolygonCoordinates[0]];

            const polygonFeature: Polygon = {
                type: 'Polygon',
                coordinates: [closedCoordinates],
            };
            setSelectedGeoJsonShape(polygonFeature);
        }
    }, [manualPolygonCoordinates]);

    return (
        <Dialog fullScreen onClose={onClose} open={open}>
            <DialogTitle
                sx={{
                    display: 'flex',
                    justifyContent: 'right',
                }}
            >
                <CloseIcon sx={{ cursor: 'pointer' }} onClick={onClose} />
            </DialogTitle>
            <DialogContent>
                <Box
                    sx={{
                        display: 'flex',
                    }}
                >
                    <NewZone
                        selectedGeoJsonShape={selectedGeoJsonShape}
                        addedShapesHistory={addedShapesHistory}
                        onShapesHistoryClear={(index) => {
                            setAddedShapesHistory((prevItems) => {
                                const newItems = [...prevItems];
                                newItems.splice(index, 1);
                                return newItems;
                            });
                        }}
                        onShapeAdd={(selectedGeoJsonShape) => {
                            setAddedShapesHistory((values) => [
                                ...values,
                                {
                                    name: newPolygonName,
                                    geoJson: selectedGeoJsonShape,
                                },
                            ]);
                            setManualPolygonCoordinates([]);
                            setSelectedGeoJsonShape(null);
                            setNewPolygonName(defaultPolygonName);
                        }}
                        onShapeChange={({ geoJsonShape, name }) => {
                            setManualPolygonCoordinates([]);
                            setSelectedGeoJsonShape(geoJsonShape);
                            setNewPolygonName(name);
                        }}
                        onShapeClear={() => {
                            setManualPolygonCoordinates([]);
                            setSelectedGeoJsonShape(null);
                            setNewPolygonName(defaultPolygonName);
                        }}
                        onZoneCreated={onAdd}
                    />
                    <Box sx={{ marginLeft: 3, height: 'calc(100vh - 80px)', width: '100%' }}>
                        <MapPicker
                            addedShapesHistory={addedShapesHistory}
                            selectedGeoJsonShape={selectedGeoJsonShape}
                            manualPolygonCoordinates={manualPolygonCoordinates}
                            onManualPolygonChange={(newPoint) => {
                                setNewPolygonName(defaultPolygonName);
                                setManualPolygonCoordinates((points) => [...points, newPoint]);
                            }}
                        />
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    );
};
