import React, { useMemo, useState } from 'react';

import {
    DataGrid,
    GridColDef,
    GridToolbarContainer,
    GridSlots,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    getGridDateOperators,
    getGridStringOperators,
    GridRowParams,
    getGridNumericOperators,
} from '@mui/x-data-grid';
import { transformKeyToTitle } from '../utils/transformKeyToTitle';
import Button from '@mui/material/Button';
import RefreshIcon from '@mui/icons-material/Refresh';
import { formatDate } from '../utils/formatDate';

import { useAppDispatch, useAppSelector } from '../store/hooks';
import { loadZones, selectZones } from '../store/maps.reducer';
import { CopyTextCell } from './GridCells/CopyTextCell';
import { ZoneListItem } from '../interfaces';
import { SelectedPolygonDialog } from './SelectedPolygonDialog';
import AddIcon from '@mui/icons-material/Add';
import { AddZoneDialog } from './AddZoneDialog';
import { useManageGrid } from '../hooks/useManageGrid';
import { getZoneById } from '../api/getZoneById';
import { ZoneActionsCell } from './GridCells/ZoneActionsCell';

function Toolbar() {
    const dispatch = useAppDispatch();
    const [addZoneDialogOpened, setAddZoneDialogOpened] = useState<boolean>(false);

    const onReload = () => {
        dispatch(loadZones());
    };
    const onAdd = () => {
        setAddZoneDialogOpened(true);
    };

    return (
        <>
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <Button startIcon={<RefreshIcon />} color="primary" onClick={onReload}>
                    Reload
                </Button>
                <Button startIcon={<AddIcon />} color="primary" onClick={onAdd}>
                    Add
                </Button>
            </GridToolbarContainer>

            {addZoneDialogOpened && (
                <AddZoneDialog
                    open={addZoneDialogOpened}
                    onAdd={() => {
                        setAddZoneDialogOpened(false);
                        onReload();
                    }}
                    onClose={() => {
                        setAddZoneDialogOpened(false);
                    }}
                />
            )}
        </>
    );
}

export const ZonesTableGrid = () => {
    const zones = useAppSelector(selectZones);
    const [selectedRow, setSelectedRow] = useState<ZoneListItem | null>(null);
    const {
        apiRef,
        sortModel,
        columnsConfig,
        columnsWidth,
        gridFilterModel,
        handleColumnVisibilityChange,
        handleColumnWidthChange,
        handleSortModelChange,
        handleFilterModelChange,
        onCellKeyDown,
        gridStateSynced,
        getViewRows,
    } = useManageGrid('map/zones');

    const columns: GridColDef[] = useMemo(() => {
        return [
            { key: 'id', renderCell: CopyTextCell },
            { key: 'name', renderCell: CopyTextCell },
            { key: 'actions', renderCell: ZoneActionsCell, width: 200 },
            {
                key: 'area',
                valueFormatter: (value: number) => (value ? `${value} км²` : ''),
                width: 150,
                filterOperators: getGridNumericOperators,
            },
            { key: 'createdBy', renderCell: CopyTextCell },
            {
                key: 'createdAt',
                valueFormatter: formatDate,
                filterOperators: getGridDateOperators,
            },
        ].map((col) => {
            return {
                field: col.key,
                // type: (col.type || 'string') as GridColType,
                headerName: transformKeyToTitle(col.key),
                renderCell: col.renderCell,
                valueFormatter: col.valueFormatter,
                width: columnsWidth[col.key] || col.width || 400,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                filterOperators: col.filterOperators ? col.filterOperators() : getGridStringOperators(),
            };
        });
    }, [columnsWidth]);

    const rows = zones?.map((zone) => {
        const row: any = {
            ...zone,
            id: zone._id,
        };

        if (row.createdAt) row.createdAt = new Date(row.createdAt);
        row.area = zone.area || 0;

        return row;
    });

    const onRowDoubleClick = (params: GridRowParams) => {
        setSelectedRow(params.row as ZoneListItem);
    };

    const viewRows = useMemo(() => {
        const rows = getViewRows();
        if (rows) {
            return rows as ZoneListItem[];
        }
        return zones;
    }, [zones, selectedRow]);

    return (
        <>
            {gridStateSynced && (
                <>
                    <DataGrid
                        apiRef={apiRef}
                        rows={rows}
                        columns={columns}
                        slots={{
                            toolbar: Toolbar as GridSlots['toolbar'],
                        }}
                        onCellKeyDown={onCellKeyDown}
                        disableRowSelectionOnClick
                        onRowDoubleClick={onRowDoubleClick}
                        sortModel={sortModel}
                        filterModel={gridFilterModel}
                        onSortModelChange={handleSortModelChange}
                        onColumnWidthChange={handleColumnWidthChange}
                        onColumnVisibilityModelChange={handleColumnVisibilityChange}
                        onFilterModelChange={handleFilterModelChange}
                        columnVisibilityModel={columnsConfig}
                    />

                    {selectedRow && viewRows && (
                        <SelectedPolygonDialog
                            list={viewRows}
                            getEntity={getZoneById}
                            open={!!selectedRow}
                            selectedRow={selectedRow}
                            onSelectedRowChange={(row) => {
                                setSelectedRow(row as ZoneListItem);
                            }}
                            onClose={() => {
                                setSelectedRow(null);
                            }}
                        />
                    )}
                </>
            )}
        </>
    );
};
