import React from 'react';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { MapPolygonPreviewer } from './MapPolygonPreviewer';
import Box from '@mui/material/Box';
import { MapUploadListItem } from '../interfaces';

interface Props {
    open: boolean;
    mapUpload: MapUploadListItem;
    onClose: () => void;
}

export const SelectedMapUploadDialog = ({ open, mapUpload, onClose }: Props) => {
    return (
        <Dialog fullScreen onClose={onClose} open={open}>
            <DialogTitle
                sx={{
                    display: 'flex',
                    justifyContent: 'right',
                }}
            >
                <CloseIcon sx={{ cursor: 'pointer' }} onClick={onClose} />
            </DialogTitle>
            <DialogContent>
                <Box
                    sx={{
                        display: 'flex',
                    }}
                >
                    <Box
                        sx={{
                            height: 'calc(100vh - 80px)',
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <MapPolygonPreviewer key={mapUpload._id} geoJson={mapUpload.geoJson} />
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    );
};
