import { GridCellParams } from '@mui/x-data-grid';
import { Clear as ClearIcon, Done as DoneIcon } from '@mui/icons-material';
import React from 'react';
import { VersionValidity } from '../../interfaces';

export const UpToDateCell = (cellValues: GridCellParams) => {
    return (
        <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
            {cellValues.value === VersionValidity.VALID ? (
                <DoneIcon style={{ color: '#458549' }} />
            ) : (
                <ClearIcon style={{ color: '#f94f4b' }} />
            )}
        </div>
    );
};
