import { GridCellParams } from '@mui/x-data-grid';
import { IconButton, Tooltip } from '@mui/material';
import * as React from 'react';
import { navigateToTilesViewer } from '../SourceTilesViewer/navigateToTilesViewer';
import ZoomInMapIcon from '@mui/icons-material/ZoomInMap';
import { MapUploadListItem } from '../../interfaces';

export const MapUploadActionsCell = (cellValues: GridCellParams) => {
    const mapUpload = cellValues.row as MapUploadListItem;

    return (
        <>
            {mapUpload.status === 'COMPLETED' && (
                <>
                    <span>
                        <Tooltip title="Переглянути сорси зони" placement="top">
                            <IconButton
                                size="small"
                                onClick={async () => {
                                    navigateToTilesViewer({
                                        mapUploadId: mapUpload._id,
                                    });
                                }}
                                edge="end"
                                aria-label="delete"
                            >
                                <ZoomInMapIcon />
                            </IconButton>
                        </Tooltip>
                    </span>
                </>
            )}
        </>
    );
};
