import { MultiPolygon, Polygon } from 'geojson';
import { center } from '@turf/turf';
import { LatLng } from '../interfaces';

export const getGeoJsonPolygonCenter = (geoJson: Polygon | MultiPolygon): LatLng => {
    // Calculate the center of the GeoJSON Polygon or MultiPolygon
    const turfCenter = center(geoJson).geometry.coordinates;

    // Convert Turf.js center output to LatLng format
    return { lat: turfCenter[1], lng: turfCenter[0] };
};
