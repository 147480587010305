import axios from 'axios';
import config from '../config';
import { Zone } from '../interfaces';

export const getZoneById = (id: string): Promise<Zone> => {
    const url = `${config.API_URL}/maps/zones/${id}`;

    return axios.get(url).then((response: any) => {
        return response.data.data.zone;
    });
};
