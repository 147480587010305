import { IconButton, Tooltip } from '@mui/material';
import { copyTextToClipboard } from '../utils/copyTextToClipboard';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import React, { useState } from 'react';

export const CopyButton = ({ value }: { value: string }) => {
    const [title, setTitle] = useState<string>('Скопіювати');

    return (
        <Tooltip title={title} placement="top">
            <IconButton
                onMouseLeave={() => {
                    setTitle('Скопіювати');
                }}
                onClick={() => {
                    copyTextToClipboard(value);
                    setTitle('Скопійовано');
                }}
                size={'small'}
                sx={{ marginLeft: 1 }}
                aria-label="copy"
            >
                <ContentCopyIcon fontSize="small" />
            </IconButton>
        </Tooltip>
    );
};
