import Box from '@mui/material/Box';
import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { loadOrganizations, selectOrganizations } from '../store/organizations.reducer';
import { OrganizationsTableGrid } from '../Components/OrganizationsTableGrid';

export const Organizations = () => {
    const dispatch = useAppDispatch();
    const organizations = useAppSelector(selectOrganizations);

    useEffect(() => {
        dispatch(loadOrganizations());
    }, []);

    return (
        <Box sx={{ width: '100%', padding: '20px', height: '100vh' }}>
            {organizations && <OrganizationsTableGrid organizations={organizations} />}
        </Box>
    );
};
