import { useAppDispatch, useAppSelector } from '../store/hooks';
import { loadMapUploads, loadZones, selectZones } from '../store/maps.reducer';
import { useEffect } from 'react';
import { ZonesTableGrid } from '../Components/ZonesTableGrid';

export const Zones = () => {
    const dispatch = useAppDispatch();
    const zones = useAppSelector(selectZones);

    useEffect(() => {
        dispatch(loadZones());
        dispatch(loadMapUploads());
    }, []);

    return <>{zones && <ZonesTableGrid />}</>;
};
