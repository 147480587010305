import { Feature, MultiPolygon, Polygon } from 'geojson';
import { union } from '@turf/turf';

export function mergeGeoJsonShapesIntoMultiPolygon(shapes: Array<Polygon | MultiPolygon>): MultiPolygon {
    // Convert all shapes to Turf.js Feature objects with strict types
    const features: Feature<Polygon | MultiPolygon>[] = shapes.map((shape) => ({
        type: 'Feature',
        geometry: shape,
        properties: {},
    }));

    // Initialize the union result as null
    let merged: Feature<Polygon | MultiPolygon> | null = null;

    // Perform union iteratively on the features
    for (const feature of features) {
        if (!merged) {
            merged = feature; // Initialize with the first feature
        } else {
            const unionResult = union(merged, feature);
            if (
                unionResult &&
                (unionResult.geometry.type === 'Polygon' || unionResult.geometry.type === 'MultiPolygon')
            ) {
                merged = unionResult as Feature<Polygon | MultiPolygon>;
            } else {
                throw new Error('Unexpected geometry type during union operation.');
            }
        }
    }

    // Return the merged geometry as a MultiPolygon
    if (merged?.geometry.type === 'MultiPolygon') {
        return merged.geometry;
    } else if (merged?.geometry.type === 'Polygon') {
        return {
            type: 'MultiPolygon',
            coordinates: [merged.geometry.coordinates],
        };
    } else {
        throw new Error('Failed to merge shapes into a MultiPolygon.');
    }
}
