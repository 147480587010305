import { MultiPolygon, Polygon } from 'geojson';

interface NavigateToTilesViewerParams {
    geoJson?: Polygon | MultiPolygon;
    processedMapName?: string;
    mapUploadId?: string;
    processedMapNames?: string[]; // Added to support an array of event names
}

export const navigateToTilesViewer = ({
    geoJson,
    processedMapName,
    mapUploadId,
    processedMapNames,
}: NavigateToTilesViewerParams) => {
    const params = new URLSearchParams();

    if (geoJson) {
        const geoJsonKey = `geoJson_localstorage`;
        localStorage.setItem(geoJsonKey, JSON.stringify(geoJson));
        params.append('geoJsonKey', geoJsonKey);
    }

    if (processedMapName) {
        params.append('processedMapName', encodeURIComponent(processedMapName));
    }

    if (mapUploadId) {
        params.append('mapUploadId', encodeURIComponent(mapUploadId));
    }

    if (processedMapNames && processedMapNames.length > 0) {
        const processedMapNamesParam = encodeURIComponent(JSON.stringify(processedMapNames));
        params.append('processedMapNames', processedMapNamesParam);
    }

    const url = `/tiles-viewer?${params.toString()}`;
    window.open(url, '_blank');
};
