import { MultiPolygon, Polygon } from 'geojson';
import React, { useEffect } from 'react';
import { useMap } from 'react-leaflet';
import { getGeoJsonPolygonBounds } from '../../utils/getGeoJsonPolygonBounds';

interface MapFitBoundsProps {
    geoJson: Polygon | MultiPolygon;
}

export const MapFitBounds: React.FC<MapFitBoundsProps> = ({ geoJson }) => {
    const map = useMap();

    useEffect(() => {
        if (geoJson) {
            const bounds = getGeoJsonPolygonBounds(geoJson);

            map.fitBounds(bounds);
        }
    }, [geoJson, map]);

    return null; // This component does not render anything
};
