import { GridCellParams } from '@mui/x-data-grid';
import React, { useState } from 'react';
import { DeviceWithSticks } from '../../interfaces';
import { VersionDialog } from '../VersionDialog';

export const VersionsCell = (cellValues: GridCellParams) => {
    const [selectedDevice, setSelectedDevice] = useState<DeviceWithSticks | null>(null);
    const [isVersionDialogOpen, setIsVersionDialogOpen] = useState<boolean>(false);

    const handleIsVersionDialogClose = () => {
        setSelectedDevice(null);
        setIsVersionDialogOpen(false);
    };

    return (
        <>
            <div
                style={{ fontWeight: 500, cursor: cellValues.value !== 0 ? 'pointer' : '' }}
                onClick={() => {
                    if (!cellValues.value) {
                        return;
                    }
                    setSelectedDevice(cellValues.row);
                    setIsVersionDialogOpen(true);
                }}
            >
                <span>{cellValues.value as number}</span>
            </div>

            {isVersionDialogOpen && selectedDevice && (
                <VersionDialog
                    version={selectedDevice.systemVersion!}
                    open={isVersionDialogOpen}
                    onClose={handleIsVersionDialogClose}
                />
            )}
        </>
    );
};
